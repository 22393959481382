<script setup lang="ts">
import { useLayout } from '@/shared/composables/layout/layout.use'
import { fixOverlayScroll } from '@/shared/lib/fixOverlayScroll'

import ModalBottom from './ModalBottom.vue'
import ModalDefault from './ModalDefault.vue'

defineProps({
  background: {
    type: String,
    default: 'bg-white',
  },
  footerButtons: {
    type: String as () => 'col' | 'row',
    default: '',
  },
})

const emit = defineEmits(['onOverlayClick'])

const model = defineModel({ type: Boolean, default: false })

const { isDesktop } = useLayout()

watch(
  () => model.value,
  (opened) => {
    fixOverlayScroll(opened)
  },
)

const onOverlayClick = () => emit('onOverlayClick')
</script>

<template>
  <div>
    <ModalDefault
      v-if="isDesktop"
      v-model="model"
      :footer-buttons="footerButtons"
      :background="background"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #default>
        <slot />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </ModalDefault>
    <ModalBottom
      v-if="!isDesktop"
      v-model="model"
      :footer-buttons="footerButtons"
      :background="background"
      @on-overlay-click="onOverlayClick"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #default>
        <slot />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </ModalBottom>
  </div>
</template>
