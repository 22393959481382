<script setup lang="ts">
import { fixOverlayScroll } from '@/shared/lib/fixOverlayScroll'

defineProps({
  background: {
    type: String,
    default: 'bg-white',
  },
  footerButtons: {
    type: String as () => 'col' | 'row',
    default: '',
  },
})

const model = defineModel({ type: Boolean, default: false })

const emit = defineEmits(['onOverlayClick'])

const modalDOMEl = ref<HTMLDivElement | null>(null)
let startHeight = 0
let baseOffset = 0

watch(
  () => model.value,
  async (opened) => {
    fixOverlayScroll(opened)
    await nextTick()
    if (opened && modalDOMEl.value) {
      startHeight = modalDOMEl.value.offsetHeight
      baseOffset = (startHeight * 20) / 100 // find 20 percents from height
    }
  },
)

const onOverlayClick = () => {
  emit('onOverlayClick')
}

function onPointerUp() {
  if (!modalDOMEl.value) return
  if (modalDOMEl.value.offsetHeight >= startHeight - baseOffset) {
    modalDOMEl.value.style.height = `${startHeight}px`
  } else {
    emit('onOverlayClick')
  }
}

function onPointerMove(e: { offsetY: number }) {
  if (!modalDOMEl.value) return
  const elementHeight = modalDOMEl.value.offsetHeight
  const isPositiveNumber = e.offsetY > 0
  if (isPositiveNumber) {
    const newHeight = elementHeight - Math.abs(e.offsetY)
    modalDOMEl.value.style.height = newHeight + 'px'
  } else {
    const newHeight = elementHeight + Math.abs(e.offsetY)
    modalDOMEl.value.style.height = newHeight > startHeight ? startHeight + 'px' : newHeight + 'px'
  }
}
</script>

<template>
  <Transition name="fade-from-bottom">
    <div
      v-if="model"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 flex items-end justify-center bg-black/75"
      @click="onOverlayClick"
    >
      <div
        v-bind="$attrs"
        ref="modalDOMEl"
        class="max-h-screen inner-modal-wrapper rounded-t-16 flex w-full flex-col justify-between p-16"
        :class="background"
        @click.stop
      >
        <div v-if="$slots.header" class="mb-12 shrink-0 grow-0 relative">
          <div
            class="relative w-full h-36 touch-none before:absolute before:left-1/2 before:-top-8 before:-translate-x-1/2 before:w-28 before:rounded-8 before:h-4 before:bg-black/45"
            @pointerup="onPointerUp"
            @pointerleave="onPointerUp"
            @pointermove="onPointerMove"
          ></div>
        </div>
        <div
          class="max-h-[80vh] shrink grow overflow-x-hidden overflow-y-scroll"
          :class="{
            'pt-12': $slots.header,
            'pb-12': $slots.footer,
          }"
        >
          <slot />
        </div>
        <div
          v-if="$slots.footer"
          class="shrink-0 grow-0 pt-12"
          :class="{
            'flex gap-16': footerButtons === 'row',
            'flex flex-col gap-8': footerButtons === 'col',
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Transition>
</template>
